<template>
  <el-dialog
    title="修改收货信息"
    :visible.sync="visible"
    width="1200px"
    :close-on-click-modal="false"
    :before-close="doCancel"
  >
    <div class="address-form">
      <el-form :model="form" ref="form">
        <div class="table-wrapper">
          <div v-for="(address, i) in form.tableData" :key="i">
            <!-- 地址列表 -->
            <el-table :data="[address]" border>
              <el-table-column prop="id" label="序号" width="50" align="center">
                <template>
                  {{ i + 1 }}
                </template>
              </el-table-column>
              <el-table-column prop="id" label="收货地址" align="center" width="300px">
                <template slot-scope="{ row }">
                  <el-form-item
                    :prop="'tableData.' + i + '.addressConfigId'"
                    :rules="[
                      {
                        required: true,
                        message: $t('placeholder.plsSel'),
                        trigger: 'change',
                      },
                    ]"
                  >
                    <el-select
                      :placeholder="$t('placeholder.plsSel')"
                      clearable
                      filterable
                      popper-class="address-popper"
                      v-model="address.addressConfigId"
                      :disabled="row.deleteFlag === 0"
                      @change="changeAddress(address)"
                      style="width:100%"
                    >
                      <div
                          
                          style="
                          display: flex;    
                          font-size: 14px;
                          color: #606266;
                          background: #f5f7fa;
                          position: absolute;
                          left: 0;
                          top: 0;
                          z-index: 9999;
                        "
                      >
                        <span style="width: 300px; padding: 5px 8px">
                          Shipping Address
                        </span>
                        <span style="width: 80px; padding: 5px 8px">
                          Country
                        </span>
                        <span style="width: 140px; padding: 5px 8px">
                          Company
                        </span>
                        <span style="width: 120px; padding: 5px 8px">
                          Consignee
                        </span>
                        <span style="width: 140px; padding: 5px 8px">
                          Contact Phone
                        </span>
                      </div>

                      <el-option
                        v-for="item in receivingAddressDTOS"
                        :key="item.addressConfigId"
                        :value="item.addressConfigId"
                        :label="item.address"
                      >
                        <template #default>
                          <div
                            @click="changeAddress(address)"
                            style="
                              display: flex;
                              margin: 0 -20px;
                              color: #666;
                              line-height: 20px;
                            "
                          >
                            <span
                              style="
                                width: 300px;
                                padding: 5px 8px;
                                white-space: normal;
                                overflow-wrap: break-word;
                              "
                            >
                              {{ item.address }}
                            </span>
                            <span
                              style="
                                width: 80px;
                                padding: 5px 8px;
                                white-space: normal;
                                overflow-wrap: break-word;
                              "
                            >
                              {{ item.nation }}
                            </span>
                            <span
                              style="
                                width: 140px;
                                padding: 5px 8px;
                                white-space: normal;
                                overflow-wrap: break-word;
                              "
                            >
                              {{ item.companyName }}
                            </span>
                            <span
                              style="
                                width: 120px;
                                padding: 5px 8px;
                                white-space: normal;
                                overflow-wrap: break-word;
                              "
                            >
                              {{ item.consigneeName }}
                            </span>
                            <span
                              style="
                                width: 140px;
                                padding: 5px 8px;
                                white-space: normal;
                                overflow-wrap: break-word;
                              "
                            >
                              {{ item.consigneePhone }}
                            </span>
                          </div>
                        </template>
                      </el-option>
                    </el-select>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column
                prop="country"
                label="国家"
                width="80px"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="receivingCompany"
                label="收货公司"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="receivingPerson"
                label="收货人"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="receivingPhone"
                label="联系电话"
                align="center"
              ></el-table-column>
              <el-table-column prop="" label="操作" width="100" align="center">
                <template slot-scope="{ row }">
                  <el-button
                    circle
                    icon="el-icon-edit"
                    :disabled="row.deleteFlag === 0"
                    @click="editAddress(address)"
                    type="primary"
                  ></el-button>
                  <el-button
                    circle
                    icon="el-icon-delete"
                    :disabled="row.deleteFlag === 0"
                    @click="deleteAddress(i)"
                  ></el-button>
                </template>
              </el-table-column>
            </el-table>
            <!-- 产品列表 -->
            <el-table
              :ref="'table' + i"
              :data="address.productDetails"
              border
              @expand-change="
                (row, expandedRows) => changeTable(row, expandedRows, i)
              "
              :span-method="
                (obj) => arraySpanMethod(obj, address.productDetails)
              "
            >
              <!-- :expand-row-keys="expandedRowKeys" -->
              <el-table-column type="expand" width="50">
                <template slot-scope="scope" v-if="scope.row.childProducts">
                  <el-table
                    :data="scope.row.childProducts"
                    border
                    :span-method="
                      (obj) => arraySpanMethod(obj, scope.row.childProducts)
                    "
                  >
                    <el-table-column width="50"></el-table-column>
                    <el-table-column
                      label="产品图"
                      align="center"
                      width="120"
                      prop="productImg"
                    >
                      <template slot-scope="{ row }">
                        <ProductImg
                          :product="row"
                          :is-main="false"
                        ></ProductImg>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="产品信息"
                      align="left"
                      prop="productInfo"
                    >
                      <template slot-scope="{ row }">
                        <ProductInfo :product="row"></ProductInfo>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="订单数量"
                      align="center"
                      prop="productNum"
                      width="100"
                    ></el-table-column>

                    <el-table-column
                      label="分配数量"
                      align="center"
                      prop="planShippingQuantity"
                      width="140"
                    >
                      <template slot-scope="childScope">
                        <el-form-item
                          :prop="
                            'tableData.' +
                            [i] +
                            '.productDetails.' +
                            [scope.$index] +
                            '.childProducts.' +
                            [childScope.$index] +
                            '.planShippingQuantity'
                          "
                          :rules="[
                            {
                              pattern: /^(0|[1-9]\d*)$/,
                              message: $t('placeholder.M2026'),
                              trigger: 'blur',
                            },
                          ]"
                        >
                          <el-input
                            v-model="childScope.row.planShippingQuantity"
                            :disabled="childScope.row.updateFlag === 0"
                          ></el-input>
                        </el-form-item>
                      </template>
                    </el-table-column>
                    <!-- 计划装运日期 -->
                    <el-table-column
                      label="计划装运日期"
                      align="center"
                      prop="planShippingDate"
                      width="180"
                      label-class-name="require-th"
                    >
                      <template slot-scope="childScope">
                        <el-form-item
                          :prop="
                            'tableData.' +
                            [i] +
                            '.productDetails.' +
                            [scope.$index] +
                            '.childProducts.' +
                            [childScope.$index] +
                            '.planShippingDate'
                          "
                          :rules="[
                            {
                              required: true,
                              message: $t('placeholder.plsSel'),
                              trigger: 'blur',
                            },
                          ]"
                        >
                          <el-date-picker
                            style="width: 160px"
                            value-format="yyyy-MM-dd"
                            format="yyyy-MM-dd"
                            :disabled="childScope.row.updateFlag === 0"
                            v-model="childScope.row.planShippingDate"
                            type="date"
                            placeholder="选择日期"
                          ></el-date-picker>
                        </el-form-item>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="运输方式"
                      align="center"
                      prop="shippingMethod"
                      width="180"
                      label-class-name="require-th"
                    >
                      <template slot-scope="childScope">
                        <el-form-item
                          :prop="
                            'tableData.' +
                            [i] +
                            '.productDetails.' +
                            [scope.$index] +
                            '.childProducts.' +
                            [childScope.$index] +
                            '.shippingMethodId'
                          "
                          :rules="[
                            {
                              required: true,
                              message: $t('placeholder.plsInput'),
                              trigger: ['blur', 'change'],
                            },
                          ]"
                        >
                          <el-select
                            v-model="childScope.row.shippingMethodId"
                            :disabled="childScope.row.updateFlag === 0"
                            :placeholder="$t('placeholder.plsSel')"
                            clearable
                            @change="
                              changeShipMethod(
                                childScope.row,
                                childScope.row.shippingMethodId
                              )
                            "
                          >
                            <el-option
                              v-for="item in shippingMethodList"
                              :key="item.id"
                              :label="item.transportWayCn"
                              :value="item.id"
                            />
                          </el-select>
                        </el-form-item>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="客户交期"
                      align="center"
                      prop="customerDeliveryDate"
                      width="180"
                      label-class-name="require-th"
                    >
                      <template slot-scope="childScope">
                        <el-form-item
                          :prop="
                            'tableData.' +
                            [i] +
                            '.productDetails.' +
                            [scope.$index] +
                            '.childProducts.' +
                            [childScope.$index] +
                            '.customerDeliveryDate'
                          "
                          :rules="[
                            {
                              required: true,
                              message: $t('placeholder.plsInput'),
                              trigger: ['blur', 'change'],
                            },
                          ]"
                        >
                          <el-date-picker
                            style="width: 160px"
                            value-format="yyyy-MM-dd"
                            format="yyyy-MM-dd"
                            :disabled="childScope.row.updateFlag === 0"
                            v-model="childScope.row.customerDeliveryDate"
                            type="date"
                            placeholder="选择日期"
                          ></el-date-picker>
                          <p class="text-left">
                            <el-checkbox
                              v-model="childScope.row.firmFlag"
                              :disabled="childScope.row.updateFlag === 0"
                              :true-label="1"
                              :false-label="0"
                            >
                              Firm交期
                            </el-checkbox>
                          </p>
                        </el-form-item>
                      </template>
                    </el-table-column>
                  </el-table>
                </template>
              </el-table-column>
              <el-table-column
                label="产品图"
                align="center"
                width="120"
                prop="productImg"
              >
                <template slot-scope="{ row }">
                  <ProductImg
                    :product="row"
                    :is-main="row.childProducts && row.childProducts.length > 0"
                    :show-main="
                      row.childProducts && row.childProducts.length > 0
                    "
                  ></ProductImg>
                </template>
              </el-table-column>
              <el-table-column label="产品信息" align="left" prop="productInfo">
                <template slot-scope="{ row }">
                  <ProductInfo :product="row"></ProductInfo>
                </template>
              </el-table-column>
              <el-table-column
                label="订单数量"
                align="center"
                prop="productNum"
                width="100"
              ></el-table-column>

              <el-table-column
                label="分配数量"
                align="center"
                prop="planShippingQuantity"
                width="140"
              >
                <template slot-scope="scope">
                  <el-form-item
                    v-if="
                      !scope.row.childProducts ||
                      scope.row.childProducts.length === 0
                    "
                    :prop="
                      'tableData.' +
                      [i] +
                      '.productDetails.' +
                      [scope.$index] +
                      '.planShippingQuantity'
                    "
                    :rules="[
                      {
                        pattern: /^(0|[1-9]\d*)$/,
                        message: $t('placeholder.M2026'),
                        trigger: 'blur',
                      },
                    ]"
                  >
                    <el-input
                      v-model="scope.row.planShippingQuantity"
                      :disabled="scope.row.updateFlag === 0"
                    ></el-input>
                  </el-form-item>
                  <span v-else>--</span>
                </template>
              </el-table-column>
              <!-- 计划装运日期 -->
              <el-table-column
                label="计划装运日期"
                align="center"
                prop="planShippingQuantity"
                width="180"
                label-class-name="require-th"
              >
                <template slot-scope="scope">
                  <el-form-item
                    v-if="
                      !scope.row.childProducts ||
                      scope.row.childProducts.length === 0
                    "
                    :prop="
                      'tableData.' +
                      [i] +
                      '.productDetails.' +
                      [scope.$index] +
                      '.planShippingDate'
                    "
                    :rules="[
                      {
                        required: true,
                        message: $t('placeholder.plsInput'),
                        trigger: 'blur',
                      },
                    ]"
                  >
                    <el-date-picker
                      value-format="yyyy-MM-dd"
                      format="yyyy-MM-dd"
                      :disabled="scope.row.updateFlag === 0"
                      style="width: 160px"
                      v-model="scope.row.planShippingDate"
                      type="date"
                      placeholder="选择日期"
                    ></el-date-picker>
                  </el-form-item>
                  <span v-else>--</span>
                </template>
              </el-table-column>
              <el-table-column
                label="运输方式"
                align="center"
                prop="shippingMethod"
                width="180"
                label-class-name="require-th"
              >
                <template slot-scope="scope">
                  <el-form-item
                    v-if="
                      !scope.row.childProducts ||
                      scope.row.childProducts.length === 0
                    "
                    :disabled="scope.row.updateFlag === 0"
                    :prop="
                      'tableData.' +
                      [i] +
                      '.productDetails.' +
                      [scope.$index] +
                      '.shippingMethodId'
                    "
                    :rules="[
                      {
                        required: true,
                        message: $t('placeholder.plsInput'),
                        trigger: ['blur', 'change'],
                      },
                    ]"
                  >
                    <el-select
                      v-model="scope.row.shippingMethodId"
                      :disabled="scope.row.updateFlag === 0"
                      :placeholder="$t('placeholder.plsSel')"
                      clearable
                      @change="
                        changeShipMethod(scope.row, scope.row.shippingMethodId)
                      "
                    >
                      <el-option
                        v-for="item in shippingMethodList"
                        :key="item.id"
                        :label="item.transportWayCn"
                        :value="item.id"
                      />
                    </el-select>
                  </el-form-item>
                  <span v-else>--</span>
                </template>
              </el-table-column>
              <el-table-column
                label="客户交期"
                align="center"
                prop="customerDeliveryDate"
                width="180"
                label-class-name="require-th"
              >
                <template slot-scope="scope">
                  <el-form-item
                    v-if="
                      !scope.row.childProducts ||
                      scope.row.childProducts.length === 0
                    "
                    :prop="
                      'tableData.' +
                      [i] +
                      '.productDetails.' +
                      [scope.$index] +
                      '.customerDeliveryDate'
                    "
                    :rules="[
                      {
                        required: true,
                        message: $t('placeholder.plsSel'),
                        trigger: ['blur', 'change'],
                      },
                    ]"
                  >
                    <el-date-picker
                      :disabled="scope.row.updateFlag === 0"
                      style="width: 160px; margin-top: 33px"
                      value-format="yyyy-MM-dd"
                      format="yyyy-MM-dd"
                      v-model="scope.row.customerDeliveryDate"
                      type="date"
                      placeholder="选择日期"
                    ></el-date-picker>
                    <p class="text-left">
                      <el-checkbox
                        v-model="scope.row.firmFlag"
                        :true-label="1"
                        :false-label="0"
                        :disabled="scope.row.updateFlag === 0"
                      >
                        Firm交期
                      </el-checkbox>
                    </p>

                    <p></p>
                  </el-form-item>
                  <span v-else>--</span>
                </template>
              </el-table-column>
            </el-table>
            <div
              v-if="i !== form.tableData.length - 1"
              class="split-line"
            ></div>
          </div>
        </div>
        <!-- 备注 -->
        <el-form-item
          class="mt-20"
          label="修改原因"
          label-position="top"
          prop="updateRemark"
          :rules="[
            {
              required: true,
              message: $t('placeholder.plsInput'),
              trigger: 'blur',
            },
          ]"
        >
          <el-input
            type="textarea"
            v-model="form.updateRemark"
            maxlength="500"
            placeholder="请输入备注"
          ></el-input>
        </el-form-item>
        <!-- 地址按钮 -->
        <div class="address-btn">
          <p class="page-link mr-5" @click="addTableAddress">添加收货地址</p>
          <p class="page-link ml-5" @click="addRemoteAddress">新增收货地址</p>
        </div>
      </el-form>
    </div>
    <div class="footer">
      <p>提示：本次修改无审核，但是会记录日志和钉钉消息提醒。</p>
      <div>
        <el-button @click="doCancel">取 消</el-button>
        <el-button type="primary" @click="doConfirm">确 定</el-button>
      </div>
    </div>
    <AddressAddModel
      ref="addressAddModel"
      :self-close="false"
      @addressConfigSave="addAddress"
    ></AddressAddModel>
  </el-dialog>
</template>

<script>
  import AddressAddModel from '@/views/new-customer-manage/customer-center/components/address-add-model'
  import ProductImg from './ProductImg.vue'
  import ProductInfo from './ProductInfo.vue'
  import { merchantsAddressConfigListPage, orderShipType } from '@/api/order'
  import { OrderInteractor, CustomerManageInteractor } from '@/core'
  import { deepCopy } from 'kits'

  const mergeColumns = ['产品图', '产品信息', '订单数量']

  export default {
    name: 'ModifyDeliveryInformation',
    components: {
      ProductImg, // 产品图片
      ProductInfo, // 产品信息
      AddressAddModel, // 新增地址
    },
    data() {
      return {
        visible: false,
        form: {
          //表单
          tableData: [],
          updateRemark: '',
        },
        receivingAddressDTOS: [], // 收货地址下拉列表
        expandedRowKeys: [],
        shippingMethodList: [], // 运输方式列表
        addressItem: [], // 添加地址相关数据
      }
    },

    methods: {
      // 获取新增地址下面的产品
      getAddressItem() {
        const piNumber = this.$route.query.orderCode
        this.addressItem = OrderInteractor.getOrderShipPlanProductApi(
          piNumber
        ).then((data) => {
          if (data) {
            this.addressItem = data
          }
        })
      },
      // 编辑地址
      editAddress(address) {
        const editAddress = this.receivingAddressDTOS?.find(receivingAddress => receivingAddress.addressConfigId === address.addressConfigId)
        if(editAddress) {
          this.$refs.addressAddModel.showAddEdit({...editAddress})
        } else {
          this.$message.warning('该地址已删除，无法修改，请选择其他收货地址')
        }

      },
      // 删除地址
      deleteAddress(i) {
        if (this.form.tableData.length === 1) {
          return this.$message.warning('至少保留一个地址')
        }
        this.form.tableData.splice(i, 1)
      },

      // 修改数量
      changePlanShippingQuantity(item) {
        const { planShippingQuantity, childProducts } = item
        if (childProducts?.length > 0) {
          childProducts.forEach((childProduct) => {
            childProduct.planShippingQuantity =
              planShippingQuantity * childProduct.numDemand
          })
        }
      },

      // 切换地址
      changeAddress(address) {
        address.addressConfigIdTemp = undefined
        // 校验地址是否存在
        const existAddresses = this.form.tableData.filter(
          (item) => item.addressConfigId === address.addressConfigId
        )
        if (existAddresses?.length > 1) {
          this.$message.warning('该地址已存在，请勿重复添加')
          address.addressConfigId = ''
          return
        }
        const targetAddress = this.receivingAddressDTOS.find(
          (item) => item.addressConfigId === address.addressConfigId
        )
        if (targetAddress) {
          address.country = targetAddress.nation
          address.receivingCompany = targetAddress.companyName
          address.receivingPerson = targetAddress.consigneeName
          address.receivingPhone = targetAddress.consigneePhone
          address.destination = targetAddress.address
          address.addressConfigId = targetAddress.addressConfigId
        }
      },
      // 添加收货地址
      addTableAddress() {
        const addressItem = deepCopy(this.addressItem)
        const addressListNew = this.formatTableData([addressItem])
        this.form.tableData.push(addressListNew[0])
      },
      // 修改列表地址信息
      setItemAddress(data) {
        const tableItem = this.form.tableData.find(tableDataItem => tableDataItem.addressConfigId === data?.addressConfigId)
        if(tableItem) {
          this.$set(tableItem, 'country', data.nation)
          this.$set(tableItem, 'receivingCompany', data.companyName)
          this.$set(tableItem, 'receivingPerson', data.consigneeName)
          this.$set(tableItem, 'receivingPhone', data.consigneePhone)
          this.$set(tableItem, 'destination', data.address)
        }
      },
      // 新增收货地址
      addRemoteAddress() {
        this.$refs.addressAddModel.showAddEdit()
      },
      // 保存地址
      addAddress(data,i,callback) {
        let address =
          data.street +
          ' ' +
          (data.unit ? data.unit + ' ' : '') +
          data.city +
          ' ' +
          data.province +
          ' ' +
          data.postcode
        data.address = address
        const method = data?.addressConfigId ? 'updateShipAddressApi' : 'insertReceivingAddressApi'
        CustomerManageInteractor[method]({
          infoId: this.infoId,
          receivingAddressConfigDTOS: [data],
        }).then((res) => {
          if (res?.code === '000000') {
            // 重新获取收货地址
            this.getAddressList()
            if(typeof callback === 'function') {
              callback()
            }
            if(data?.addressConfigId) {
              this.setItemAddress(data)
            }
          }
        })
      },
      // 合并单元格
      arraySpanMethod({ row, column, rowIndex, columnIndex }, arr) {
        const rowsValue = this.findRowspanGroups(arr)
        for (let i = 0; i < rowsValue.length; i++) {
          const [start, count] = rowsValue[i]
          if (rowIndex >= start && rowIndex < start + count) {
            if (
              mergeColumns.includes(column.label) ||
              column.type === 'expand' ||
              columnIndex === 0
            ) {
              return {
                rowspan: rowIndex === start ? count : 0,
                colspan: 1,
              }
            }
          }
        }
      },
      // 获取相同shippingPlanOrderProductId的起始坐标和数量
      findRowspanGroups(arr) {
        let result = []
        let startIndex = 0
        // 遍历数组
        for (let i = 1; i <= arr.length; i++) {
          if (
            i === arr.length ||
            arr[i].shippingPlanOrderProductId !==
              arr[i - 1].shippingPlanOrderProductId
          ) {
            result.push([startIndex, i - startIndex])
            startIndex = i
          }
        }
        return result
      },

      //切换运输方式
      changeShipMethod(product, shippingMethodId) {
        if (!shippingMethodId) {
          product.shippingMethodType = ''
          product.shippingMethod = ''

          return
        }
        this.shippingMethodList.forEach((item) => {
          if (shippingMethodId == item.id) {
            product.shippingMethodType = item.transportType
            product.shippingMethod = item.transportWayCn
          }
        })
      },
      // 表格变化
      changeTable(row, expandedRows, i) {
        if (!row.childProducts?.length) {
          const ref = `table${i}`
          this.$refs[ref]?.[0]?.toggleRowExpansion(row, false)
        }
      },

      // 选择客户邮箱带出地址
      getAddressList() {
        merchantsAddressConfigListPage({ infoId: this.infoId }).then((res) => {
          this.receivingAddressDTOS = res?.data || []
          // console.log('收货地址下拉', JSON.parse(JSON.stringify(res?.data)))
          if (res?.data?.length) {
            this.form.tableData.forEach(item => {
              // 是否存在相同的addressConfigId
              const idExists = res.data.some(addressItem => item.addressConfigId === addressItem.addressConfigId)
              // 如果存在，则不做处理，正常回显即可；如果不存在，分为2种情况
              if (!idExists) {
                const itemStr = item.destination + item.receivingCompany + item.receivingPerson + item.receivingPhone
                const target = res.data.find(addressItem => {
                  const addressItemStr = addressItem.address + addressItem.companyName + addressItem.consigneeName + addressItem.consigneePhone
                  return itemStr === addressItemStr
                })
                if (target) {
                  // 1. 如果address、companyName、consigneeName、consigneePhone比较是一致的，
                  // 则说明tableData中的addressConfigId是错误的，需要更改为下拉中的addressConfigId
                  item.addressConfigId = target.addressConfigId
                } else {
                  // 2. addressConfigId没匹配上，address等字段也没匹配上，则说明tableData中的收货地址不在下拉中
                  // 此时不能将addressConfigId展示在select框中，需要显示为中文，也就是destination
                  item.addressConfigIdTemp = item.addressConfigId
                  item.addressConfigId = item.destination
                }
              }
            })
            // console.log('this.form.tableData--新', JSON.parse(JSON.stringify(this.form.tableData)))
          }
        })
      },

      // 处理数据
      formatTableData(data) {
        const addressList = deepCopy(data)
        addressList.forEach((item) => {
          if (item?.productDetails?.length) {
            // 根据主产品中的productSubList进行扁平化
            const productDetails = []
            item.productDetails.forEach((productDetail) => {
              // 扁平化子产品
              if (productDetail.childProducts) {
                let childProducts = []
                productDetail.childProducts.forEach((childProduct) => {
                  if (childProduct.productSubList) {
                    childProduct.productSubList.forEach((productSub) => {
                      childProducts.push({
                        ...childProduct,
                        ...productSub,
                      })
                    })
                  }
                })
                // console.log('childProducts', childProducts)
                productDetail.childProducts = childProducts
              }
              if (productDetail.productSubList) {
                productDetail.productSubList.forEach((productSub) => {
                  productDetails.push({
                    ...productSub,
                    ...productDetail,
                  })
                })
              } else {
                productDetails.push(productDetail)
              }
            })
            item.productDetails = productDetails
          } else {
            item.productDetails = []
          }
        })
        return addressList
      },

      showAddEdit(data, infoId) {
        this.initTableData = data
        this.infoId = infoId // 查询地址列表用
        this.form.tableData = this.formatTableData(data)
        // console.log('this.form.tableData', JSON.parse(JSON.stringify(this.form.tableData)))
        this.visible = true
        this.getAddressList()
        this.getAddressItem()
        this.getShippingMethodList()
      },

      doCancel() {
        this.visible = false
        this.form.updateRemark = ''
        this.$refs.form.clearValidate()
      },

      doConfirm() {
        this.$refs.form.validate((valid) => {
          if (valid) {
            const orderInfo = {
              piNumber: this.$route.query.orderCode,
              updateRemark: this.form.updateRemark,
            }
            const tableData = deepCopy(this.form.tableData)
            // 追加5个字段，接口需要
            tableData.forEach(item => {
              if (item.addressConfigIdTemp) {
                item.addressConfigId = item.addressConfigIdTemp
                item.addressConfigIdTemp = undefined
              }
              this.receivingAddressDTOS.forEach(addressItem => {
                if (item.addressConfigId === addressItem.addressConfigId) {
                  item.postcode = addressItem.postcode
                  item.province = addressItem.province
                  item.city = addressItem.city
                  item.street = addressItem.street
                  item.unit = addressItem.unit
                }
              })
            })
            // console.log('提交', JSON.parse(JSON.stringify(tableData)))
            // return
            OrderInteractor.saveOrderShipPlanVOApi(
              tableData,
              this.initTableData,
              orderInfo
            ).then((res) => {
              if (res?.code === '000000') {
                this.$message.success('操作成功')
                this.$emit('address-edit-success')
                this.doCancel()
              }
            })
          }
        })
      },

      // 获取收货地址下拉

      // 获取运输方式下拉
      getShippingMethodList() {
        // 获取货运方式
        orderShipType().then((res) => {
          this.shippingMethodList = res.data
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .table-wrapper {
    height: 450px;
    overflow: auto;
  }
  .split-line {
    border: 1px dashed #d7d7d7;
    margin: 10px 0;
  }
  .footer {
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
    align-items: center;
  }
  .address-btn {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    .mr-5 {
      margin-right: 5px;
    }
    .ml-5 {
      margin-left: 5px;
    }
  }
  .mt-20 {
    margin-top: 20px;
  }
  ::v-deep {
    .address-form {
      .el-form-item {
        margin-bottom: 0 !important;
        &.is-error {
          margin-bottom: 18px !important;
        }
      }
    }

  }
</style>

<style>
  .address-popper .el-select-dropdown__item {
    height: auto !important;
  }
  .address-popper .el-select-dropdown__list {
    padding-top: 31px;
  }
</style>
