@import "~@/erp/styles/variables/variables.scss";
























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.table-wrapper {
  height: 450px;
  overflow: auto;
}
.split-line {
  border: 1px dashed #d7d7d7;
  margin: 10px 0;
}
.footer {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  align-items: center;
}
.address-btn {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  .mr-5 {
    margin-right: 5px;
  }
  .ml-5 {
    margin-left: 5px;
  }
}
.mt-20 {
  margin-top: 20px;
}
::v-deep {
  .address-form {
    .el-form-item {
      margin-bottom: 0 !important;
      &.is-error {
        margin-bottom: 18px !important;
      }
    }
  }

}
