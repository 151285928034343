@import "~@/erp/styles/variables/variables.scss";















































































































































































































::v-deep {
  .el-date-editor.el-input {
    width: 100%;
  }
}
