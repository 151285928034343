@import "~@/erp/styles/variables/variables.scss";






































































































































































































































































































































































































































































































































































































































































































































::v-deep {
  .el-dialog__body {
    padding-top: 10px;
  }
}

.money-sum {
  color: #666;
  text-align: right;
  padding: 5px;

  span {
    margin: 0 5px;
  }
}
