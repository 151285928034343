@import "~@/erp/styles/variables/variables.scss";

























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.el-message-box.arrange-shipment-tip {
  .el-icon-warning {
    color: red;
  }
}
.red-pink {
  font-size: 12px;
}
.plan-bottom {
  margin-top: 10px;
  display: flex;
  .item {
    flex: 1;
  }
}
.plan-remark {
  font-weight: 650;
  color: #333333e5;
  font-size: 14px;
}
.text-9{
  color: #999 !important;
}
.shipped-flag{
    .el-checkbox{
      &.is-disabled{
        .el-checkbox__inner{
          background-color: #f2f2f2 !important;
          border-color: #f2f2f2 !important;
        }
      }
    }
  } 
