@import "~@/erp/styles/variables/variables.scss";























































.product-img-box {
  position: relative;
  height: 80px;
  width: 80px;

}
.main-child-tag {
  position: absolute;
  bottom: 0px;
  font-size: 13px;
  right: 0px;
}
.el-tag-other {
  position: absolute;
  font-size: 13px;
  top: 0px;
  right: -27px;
}
::v-deep {
  .el-image {
    height: 80px !important;
    width: 80px !important;
  }
}

.main-child-tag {
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 2.5px;
  background-color: #facd91;
  color: #7b4d12;
  &.is-main {
    background-color: #caf982;
    color: #4b7902;
  }
}
.fileAnnex-span {
  display: inline-block;
  width: 15px;
  height: 15px;
  line-height: 15px;
  text-align: center;
  background: #9e9e9e;
  color: #fff;
  border-radius: 50%;
  margin-left: 5px;
  font-size: 12px;
  position: absolute;
  right: -23px;
  top: 40px;
}
