@import "~@/erp/styles/variables/variables.scss";
































.text-left {
  text-align: left;
  p {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
