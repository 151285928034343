@import "~@/erp/styles/variables/variables.scss";














































































































































































.tips {
  display: flex;
  align-items: center;
}
.el-icon-warning {
  color: #e6a23c;
  font-size: 25px;
  margin-right: 15px;
}
