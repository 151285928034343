@import "~@/erp/styles/variables/variables.scss";


































































































































































































































































































.dialog {
  .el-form-item {
    margin-bottom: 18px;
  }
  .money-wrapper {
    display: flex;
    padding: 15px 14px;
    padding-top: 0px;
    p {
      display: flex;
      flex-direction: column;
      flex: 1;
      span.money {
        margin-top: 10px;
        font-weight: bold;
      }
    }
  }
}
