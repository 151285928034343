@import "~@/erp/styles/variables/variables.scss";























































































































































































































.upload_file {
  max-height: 70px;
  overflow-y: auto;

  .file_list {
    .file_line {
      width: 360px;
      height: 32px;
      background: #f5f7fa;
      border-radius: 3px;
      border: 1px solid #ebeef5;
      line-height: 32px;
      font-size: 12px;
      color: #606266;
      position: relative;
      display: flex;
      justify-content: flex-start;
      margin-top: 5px;

      span {
        margin-right: 15px;
      }

      .icon_file {
        font-size: 16px;
        color: #fa6400;
        position: absolute;
        left: 5px;
        top: 8px;
      }

      .file_name {
        width: 160px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .size {
        width: 55px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .icon_success {
        color: #1989fa;
      }

      .icon_del {
        font-size: 16px;
        position: absolute;
        right: 5px;
        top: 8px;
        cursor: pointer;
      }
    }
  }
}

.el-upload__tip {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
