@import "~@/erp/styles/variables/variables.scss";





























































































































































































































































































































































































































































































































































































































.content {
  padding: 0 20px 0px;
  .detail-header {
    > .el-col {
      p {
        margin-bottom: 14px;
      }
      > span {
        font-size: 16px;
        color: #000;
      }
    }
  }
  .remark {
    > .el-col {
      p {
        margin-bottom: 14px;
      }
      > span {
        font-size: 16px;
        color: #000;
      }
    }
  }
  /deep/ .el-divider--horizontal {
    margin: 30px 0;
  }
}
::v-deep {
  .el-table__expanded-cell {
    padding: 0 0 0 0;
  }
  .el-table__expand-column .el-icon {
    visibility: hidden;
  }
}
.supplierName-row {
  .supplierName-p {
    flex: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
  }
}
// 付款单详情
// 此处仅处理当前页面，后面需要在custom.scss中统一处理，这里的样式可以删除
.flie-list {
  display: flex;
  .page-link {
    flex: 1;
    overflow: hidden;
    text-align: left;
    span.ell {
      max-width: none; // 去掉max-width: 300px;
      width: 100%;
      > a {
        width: 100%;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
