@import "~@/erp/styles/variables/variables.scss";


























































































































































.dialog {
  h4 {
    margin: 10px 0 0;
  }
  .img-wrap {
    img {
      width: 150px;
      height: 150px;
      object-fit: cover;
      margin-right: 20px;
      border-radius: 2px;
      cursor: pointer;
    }
  }
}
::v-deep {
  .el-dialog__body {
    padding-top: 0;
  }
}
