@import "~@/erp/styles/variables/variables.scss";













































































































































































































































































































































































































































































































































































.custom-table .el-form-item {
  margin-bottom: 4px;
}

::v-deep {
  .required-th {
    text-align: center;
  }

  .row-expand-unhas .el-table__expand-column {
    pointer-events: none;
  }

  .row-expand-unhas .el-table__expand-column .el-icon {
    visibility: hidden;
  }
}
