@import "~@/erp/styles/variables/variables.scss";

























































































































































































































































































































































































































































































































































.el-tabs {
  padding: 0 20px;
  .el-timeline {
    padding: 0 10px !important;
  }
}
.drawer-wrap {
  overflow-y: scroll;
  .top {
    margin: 10px 0 10px 0;
    color: #333;
    line-height: 26px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    p {
      span {
        margin-right: 30px;
      }
    }
  }
  .el-timeline {
    padding: 0 30px;
  }
  .el-timeline-item {
    padding-bottom: 5px;
  }
  .blue-sty {
    color: #409eff;
    cursor: pointer;
  }
  .red-sty {
    color: red;
  }
  .time-line {
    display: flex;
    .l {
      width: 180px;
    }
    .r {
      width: calc(100% - 180px);
    }
  }
}
.btn-close {
  position: sticky;
  width: 100%;
  height: 60px;
  background: white;
  z-index: 999;
  text-align: center;
  bottom: 0;
  padding-top: 15px;
}
::v-deep {
  .el-timeline-item__content {
    color: #666;
  }
  .el-divider {
    margin: 20px 0 !important;
    background-color: #ededed;
  }
}
